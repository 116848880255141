import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {ErrorType, getHeaders} from './utils';
import {SerializedError} from '@reduxjs/toolkit';

export const apiQuotes = createApi({
    reducerPath: 'quoteApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_QUOTE_APP_API}/api/v2`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        },
    }) as BaseQueryFn<
        string | FetchArgs,
        unknown,
        ErrorType | FetchBaseQueryError | SerializedError
    >,
    tagTypes: ['Quote'],
    endpoints: () => ({}),
});
