export enum StorageKeys {
  ACCESS_TOKEN = "accessToken",
}

export type ErrorType = {
  status: number;
  data: {
    status: string;
    detail: string;
  };
};

export const getHeaders = (headers) => {
  const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  headers.set("Content-Type", "application/vnd.api+json");
  headers.set("Authorization", `Bearer ${accessToken}`);
  return headers;
};
