import { AlertMessage, ThemeProvider } from "@vacasa/react-components-lib";
import React from "react";
import "./AlertError.scss";

export default function AlertError({ theme, error }) {
  const getErrorMessage = ({ error }) => {
    if (typeof error.status === "string") {
      //Error on fetch
      return error.status + ". Reason: " + error.error;
    } else {
      // Other error by server
      const base_string =
        error.data.status + " [" + error.status + "]. Reason: ";
      switch (error.status) {
        case 401:
          return base_string + error.data.detail.reason;
        default:
          return base_string + error.data.detail.message;
      }
    }
  };

  return (
    <div className={"alert-error-container"}>
      <ThemeProvider theme={theme}>
        <AlertMessage type={"error"} text={getErrorMessage({ error })} />
      </ThemeProvider>
    </div>
  );
}
