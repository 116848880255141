export class Configuration {
    public static isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === 'production';

    static getIDPConfiguration = () => {
        const { REACT_APP_IDP_CLIENT_ID, REACT_APP_QUOTE_20_DOMAIN, REACT_APP_IDP_REDIRECT_URI, REACT_APP_CUSTOM_NODE_ENV } = process.env;
        const envs = {
            production: 'prod',
            staging: 'stage',
            development: 'stage',
            local: 'stage',
        };

        return {
            clientId: REACT_APP_IDP_CLIENT_ID,
            scopes: 'quote:read quote:write quote-unit-segment:read quote-unit-segment:write',
            audience: REACT_APP_QUOTE_20_DOMAIN,
            redirectUrl: REACT_APP_IDP_REDIRECT_URI,
            directoryHint: Configuration.isProduction ? 'onelogin' : 'email',
            env: envs[REACT_APP_CUSTOM_NODE_ENV],
            useAuthorizationContext: false
        };
    };
}
