import React, { useEffect, useState } from "react";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import "./CustomizedAccordion.scss";
import { BasicTab, BasicTable, ColumnTooltip, SidePanel } from "../index";
import { Chip, Paper } from "@material-ui/core";
import { getCapitalizeString, QuoteInspectorData, round } from "../../utils";
import { Icon, Tooltip } from "@vacasa/react-components-lib";
import { MoneyTag } from "../../assets/images";
import { Modal } from "../Modal/Modal";
import SyntaxHighlighter from "react-syntax-highlighter";
import _ from "lodash";

interface CustomizedAccordionsProps {
  notBookableReason: string;
  details: QuoteInspectorData[];
}

export const CustomizedAccordions: React.FC<CustomizedAccordionsProps> = (
  props: CustomizedAccordionsProps
) => {
  const { details, notBookableReason } = props;
  const [expanded, setExpanded] = React.useState<number | false>(0);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showSidePanel, setShowSidePanel] = useState(true);
  const [showSidePanelLoading, setShowSidePanelLoading] = useState(false);
  const [showModalOutputUnit, setShowModalOutputUnit] =
    useState<boolean>(false);
  const [JSONDetail, setJSONDetail] = useState<{ data: any; title: string }>({
    data: null,
    title: null,
  });

  const handleChange =
    (panel: number) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      setShowSidePanel(true);
    };

  const JsonDetail = () => (
    <div className="json-detail">
      <SyntaxHighlighter language="json">
        {JSON.stringify(JSONDetail.data, null, 2)}
      </SyntaxHighlighter>
    </div>
  );

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const handleActivate = () => {
    setShowSidePanel((prev) => !prev);
  };

  const openModal = (data: object, title: string) => {
    setShowSidePanelLoading(true);
    setJSONDetail({ data, title });
  };

  const closeModal = () => {
    setShowSidePanelLoading(false);
    setShowModalOutputUnit(false);
  };

  useEffect(() => {
    if (showSidePanelLoading) {
      setShowModalOutputUnit(true);
    }
  }, [showSidePanelLoading]);

  const bodyTransformationForTransformation = ({ detail }) => {
    return (
      <div>
        <ul>
          {detail.tabs.rent.segments.map((segment) => {
            return (
              <li key={segment + "-segment-index"}>
                {getCapitalizeString(segment)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const BookableEventDataLabel = ({ detail }) => {
    return (
      <>
        <Typography style={{ paddingRight: "5px" }}>
          {`${round(
            detail.initialInformation.values.rent +
              detail.initialInformation.values.fees +
              detail.initialInformation.values.taxes
          )} ${detail.initialInformation.values.currency}`}
        </Typography>
        <Typography
          style={{
            paddingRight: "5px",
            paddingTop: "3px",
            fontSize: "1rem",
          }}
        >
          {`(Rent: ${round(
            detail.initialInformation.values.rent
          )} USD , Fees: ${round(
            detail.initialInformation.values.fees
          )} USD , Taxes: ${round(
            detail.initialInformation.values.taxes
          )} USD)`}
        </Typography>
      </>
    );
  };
  const BookableEventDataBody = ({ detail }) => {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: showSidePanel ? "75%" : "100%",
            padding: "8px 16px 16px",
          }}
        >
          <div className="icons-accordion">
            <div className="labels" style={{ width: "90%", flexWrap: "wrap" }}>
              {detail.labels.map((data, index) => {
                const bodyTransformationForChips = (
                  <div key={index}>
                    <span>{data.message?.explication ?? ""}</span>
                  </div>
                );
                return (
                  <div
                    key={index}
                    className={
                      data.message ? "chips-with-icon" : "chips-without-icon"
                    }
                  >
                    <Chip
                      key={data.id + index}
                      className="label"
                      label={data.id + data.label}
                    />
                    {data.message && (
                      <ColumnTooltip
                        contentType="element"
                        content={bodyTransformationForChips}
                        hasCloseButton={true}
                        hasBottomDetailsLink={data.message.link !== null}
                        link={data.message.link}
                        title={data.message.title}
                        type={"chips"}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div
              className="icons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "10%",
              }}
            >
              <Tooltip
                onClick={() => openModal(detail.unit, "Output Unit")}
                message={"Unit Definitions"}
              >
                <span className="pointer">
                  <Icon.Search height={22} width={22} />
                </span>
              </Tooltip>
              <Modal
                title={JSONDetail?.title ?? ""}
                show={
                  showModalOutputUnit && !!JSONDetail && detail.id === expanded
                }
                onClose={closeModal}
              >
                <JsonDetail />
              </Modal>

              {!showSidePanel && (
                <Tooltip onClick={handleActivate} message={"Fees & Taxes"}>
                  <span className="pointer money">
                    <MoneyTag className="image-room" width={22} height={22} />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          <Paper elevation={3}>
            <BasicTable
              id={detail.itineraryTable.id.toString()}
              headers={detail.itineraryTable.data.headers}
              newRows={detail.itineraryTable.data.rows}
              labels={true}
              details={(json, title) => {
                openModal(json, title);
              }}
            />
          </Paper>
          <Paper
            style={{
              padding: "0 10px",
              marginTop: "15px",
              border: "1px solid #668592",
            }}
            elevation={3}
          >
            <BasicTable
              id={detail.itineraryTable.id.toString()}
              newRows={detail.totals}
              totalValue={true}
            />
          </Paper>
          <div style={{ paddingTop: "45px", display: "flex" }}>
            <span className="text-for-basic-tab">
              <b> Transformation </b>
            </span>
            <ColumnTooltip
              contentType="element"
              content={bodyTransformationForTransformation}
              hasCloseButton={true}
              hasBottomDetailsLink={false}
              title={`This scenario has ${detail.tabs.rent.segments.length} segments applied`}
              type={"transformation"}
            />
          </div>
          <Paper elevation={3} style={{ padding: "0 16px" }}>
            <BasicTab
              id={detail.id.toString()}
              data={detail.tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />
          </Paper>
        </div>
        {showSidePanel && (
          <div style={{ width: "25%", padding: "8px 0 0 16px" }}>
            <SidePanel
              handleActivate={handleActivate}
              fees={detail.fees}
              taxes={detail.taxes}
            />
          </div>
        )}
      </div>
    );
  };

  const NotBookableEventDataLabel = ({ notBookableReason }) => {
    return (
      <>
        <Typography style={{ paddingRight: "5px" }}>
          {notBookableReason}
        </Typography>
      </>
    );
  };

  const NotBookableEventDataBody = ({ notBookableReason }) => {
    return (
      <div style={{ display: "block" }}>
        <div>
          <Typography
            variant={"h6"}
            style={{
              paddingLeft: "15px",
            }}
          >
            stay not available
          </Typography>
        </div>
        <Typography style={{ paddingLeft: "15px" }}>
          Reason: {notBookableReason}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {details.length > 0 &&
        details.map((detail, index) => {
          return (
            <MuiAccordion
              square
              className={"accordion-items"}
              key={index + "-accordion"}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <MuiAccordionSummary
                aria-controls="panel1d-content"
                key={index + "accordion-summary"}
              >
                <div className="accordion-header">
                  <div className="label-principal" style={{ display: "flex" }}>
                    {!_.isNull(detail.notBookableReason) ? (
                      <NotBookableEventDataLabel
                        notBookableReason={detail.notBookableReason}
                      />
                    ) : (
                      <BookableEventDataLabel detail={detail} />
                    )}
                  </div>
                  <Typography>{detail.initialInformation.date}</Typography>
                </div>
              </MuiAccordionSummary>
              <MuiAccordionDetails
                key={index + "accordion-summary-details"}
                className="accordion-body"
              >
                {!_.isNull(detail.notBookableReason) ? (
                  <NotBookableEventDataBody
                    notBookableReason={detail.notBookableReason}
                  />
                ) : (
                  <BookableEventDataBody detail={detail} />
                )}
              </MuiAccordionDetails>
            </MuiAccordion>
          );
        })}
    </>
  );
};
