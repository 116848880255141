import React from "react";
import "./SidePanel.scss";
import {
  ExtendedFinancialItem,
  formatAsPercent,
  getCapitalizeString,
  round,
} from "../../utils";
import { Icon, Tooltip } from "@vacasa/react-components-lib";
import { Chip } from "@material-ui/core";
import { ColumnTooltip } from "../ColumnTooltip/ColumnTooltip";

interface SidePanelProps {
  handleActivate: () => void;
  fees: ExtendedFinancialItem[];
  taxes: ExtendedFinancialItem[];
}

export const SidePanel: React.FC<SidePanelProps> = (props) => {
  const { handleActivate, fees, taxes } = props;

  const bodyFeesAndTaxes = (
    conditions: { name: string; values: string[] }[]
  ) => {
    return (
      <div>
        {conditions.map((condition) => {
          return (
            <ul key={"condition-" + condition.name}>
              <li>
                <b>{getCapitalizeString(condition.name)}</b>
              </li>
              <ul>
                {condition.values.map((value) => {
                  return <li key={value}>{value}</li>;
                })}
              </ul>
            </ul>
          );
        })}
      </div>
    );
  };

  return (
    <div className="container">
      <div className="container-header">
        <div className="imagen-header">
          <Tooltip onClick={handleActivate} message={"Close Panel"}>
            <span className="pointer">
              <Icon.ChevronRight className="pointer" height={20} width={20} />
            </span>
          </Tooltip>
        </div>
        <p className="title"> Fees & Taxes </p>
      </div>
      <div className="container-body">
        <div>
          <p className="title-body">
            <b> Fee(s): </b>
          </p>
          {fees.map((fee) => {
            return (
              <div key={fee?.id} className="item-container-fees">
                <div className="item">
                  <div>
                    <div style={{ display: "flex" }}>
                      <span>
                        {fee?.id}
                        <b>&nbsp;-</b>
                      </span>
                      <div
                        className={
                          fee.conditions.length > 0 ? "title-onclick" : ""
                        }
                        style={{ margin: 0 }}
                      >
                        <div>
                          <span>&nbsp;{fee?.name}&nbsp;</span>
                          {fee.conditions.length > 0 && (
                            <ColumnTooltip
                              id={fee.id}
                              contentType="element"
                              content={bodyFeesAndTaxes(fee.conditions)}
                              hasCloseButton={true}
                              hasBottomDetailsLink={false}
                              title={`Details`}
                              type={"details"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <b>Value:</b> {fee?.evaluatedValue}
                    </div>
                  </div>
                </div>
                {fee.tag && (
                  <Chip key={fee.id} className="tag" label={fee.tag} />
                )}
                <ol style={{ listStyleType: "disc" }}>
                  {fee.targets.map((target, index) => {
                    return (
                      <li key={fee?.id + index}>
                        {" "}
                        {target?.base?.type === "rate"
                          ? formatAsPercent(100 * target?.base?.value)
                          : round(target?.base?.value)}{" "}
                        ( {target?.type}{" "}
                        {target?.base?.skip_days
                          ? ", skip " + target?.base?.skip_days + " "
                          : ""}{" "}
                        {target?.base?.skip_days
                          ? target?.base?.skip_days <= 1
                            ? "day"
                            : "days"
                          : ""}
                        ){" "}
                      </li>
                    );
                  })}
                </ol>
              </div>
            );
          })}
        </div>
        <div>
          <p className="title-body tax">
            <b> Tax(es): </b>
          </p>
          {taxes.map((tax) => {
            return (
              <div key={tax.id} className="item-container-taxes">
                <div className="item">
                  <div>
                    <div style={{ display: "flex" }}>
                      <span>
                        {tax?.id}
                        <b>&nbsp;-</b>
                      </span>
                      <div
                        className={
                          tax.conditions.length > 0 ? "title-onclick" : ""
                        }
                        style={{ margin: 0 }}
                      >
                        <div>
                          <span>&nbsp;{tax?.name}&nbsp;</span>
                          <ColumnTooltip
                            id={tax.id}
                            contentType="element"
                            content={bodyFeesAndTaxes(tax.conditions)}
                            hasCloseButton={true}
                            hasBottomDetailsLink={false}
                            title={`Details`}
                            type={"details"}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <b>Value:</b> {tax?.evaluatedValue}
                    </div>
                  </div>
                </div>
                {tax.tag && (
                  <Chip key={tax.id} className="tag" label={tax.tag} />
                )}

                <ol style={{ listStyleType: "disc" }}>
                  {tax.targets.map((target, index) => {
                    return (
                      <li key={tax.id + index}>
                        {target.base.type === "rate"
                          ? formatAsPercent(round(target.base.value))
                          : round(target.base.value)}{" "}
                        ( {target.type} ){" "}
                      </li>
                    );
                  })}
                </ol>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
