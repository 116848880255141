import React from 'react';
import {Route, RouteProps, Switch} from 'react-router-dom';
import {ContentTemplate, Home, QuoteInspector, Segment, Template} from './views';

export enum AppRoutes {
    HOME = '/',
    QUOTE_INSPECTOR = '/quotes-inspector',
    SEGMENT = '/segment',
    TEMPLATE = '/template',
    CONTENT_TEMPLATE = '/content-template',
}

const routes: RouteProps[] = [
    {path: AppRoutes.HOME, exact: true, component: Home},
    {
        path: [
            AppRoutes.QUOTE_INSPECTOR + '/channel/:channel',
            AppRoutes.QUOTE_INSPECTOR +
                '/channel/:channel/unitId/:unitId/checkin/:checkin/nights/:nights/guests/:guests/dogs/:dogs',
            AppRoutes.QUOTE_INSPECTOR +
                '/channel/:channel/unitId/:unitId/checkin/:checkin/nights/:nights/guests/:guests/dogs/:dogs/legacy_discount_id/:legacy_discount_id?',
            AppRoutes.QUOTE_INSPECTOR +
                '/channel/:channel/unitId/:unitId/checkin/:checkin/nights/:nights/guests/:guests/dogs/:dogs/quote_scenario_id/:quote_scenario_id?',
            AppRoutes.QUOTE_INSPECTOR +
                '/channel/:channel/unitId/:unitId/checkin/:checkin/nights/:nights/guests/:guests/dogs/:dogs/legacy_discount_id/:legacy_discount_id/quote_scenario_id/:quote_scenario_id?',
        ],
        exact: true,
        component: QuoteInspector,
    },
    {path: AppRoutes.SEGMENT, exact: true, component: Segment},
    {path: AppRoutes.TEMPLATE, exact: true, component: Template},
    {path: AppRoutes.CONTENT_TEMPLATE, exact: true, component: ContentTemplate},
];

export const Routes: React.FC = () => {
    return (
        <React.Fragment>
            <Switch>
                {routes.map((route, i) => (
                    <Route key={i} {...route} />
                ))}
            </Switch>
        </React.Fragment>
    );
};
