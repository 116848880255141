import React from "react";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { omit } from "lodash";
import "./BasicTable.scss";
import {getCapitalizeString, Message} from "../../utils";
import { Icon, Tooltip } from "@vacasa/react-components-lib";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] === a[orderBy]) {
    return 0;
  }
  return b[orderBy] > a[orderBy] ? 1 : -1;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headers: { [key: string]: any }[];
  tabs?: boolean;
  details?: (json: any, title: string) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headers, tabs, details } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headers.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ?? "center"}
            sortDirection={orderBy === headCell.id ? false : order}
            className={
              tabs && index === 0 ? "header first-header-for-tab" : "header"
            }
          >
            <TableSortLabel
              key={headCell.id}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={index === 0 ? createSortHandler(headCell.id) : () => {}}
            >
              <div className={index === 0 ? "wrap" : "wrap not-order"}>
                <Tooltip
                  placement={"top"}
                  message={`${
                    tabs && index !== 0 && index !== 1
                      ? headCell.label
                      : (headCell?.message as Message)?.explication
                      ? (headCell.message as Message).explication
                      : ""
                  }`}
                >
                  <b>{getCapitalizeString(headCell.id)} </b>
                </Tooltip>
              </div>
            </TableSortLabel>
            {headCell.priority && (
              <Tooltip placement={"top"} message={"Priority"}>
                <b>
                  <Chip
                    key={headCell.id}
                    className="label-priority"
                    label={headCell.priority}
                  />
                </b>
              </Tooltip>
            )}
            {headCell.details && (
              <>
                <Tooltip
                  placement={"top"}
                  message={"Availabilities"}
                  onClick={() => details(headCell.details, "Availabily")}
                >
                  <span className="pointer">
                    <Icon.PlusCircle height={20} width={20} />
                  </span>
                </Tooltip>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface BasicTableProps {
  id: string;
  headers?: { [key: string]: any }[];
  newRows: { [key: string]: any }[];
  pagination?: boolean;
  initialRowsPerPage?: number;
  labels?: boolean;
  className?: string;
  tabs?: boolean;
  totalValue?: boolean;
  details?: (json: any, title: string) => void;
}

export const BasicTable: React.FC<BasicTableProps> = (
  props: BasicTableProps
) => {
  const {
    headers,
    newRows,
    pagination,
    initialRowsPerPage,
    className,
    tabs,
    id,
    totalValue,
    details,
  } = props;
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    pagination ? initialRowsPerPage ?? 5 : newRows.length
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Object
  ) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = pagination
    ? rowsPerPage - Math.min(rowsPerPage, newRows.length - page * rowsPerPage)
    : newRows.length;

  return (
    <>
      <TableContainer style={!pagination ? { maxHeight: "318px" } : {}}>
        <Table
          key={id}
          stickyHeader={!pagination}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
          className={className}
        >
          {headers && (
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers ?? []}
              tabs={tabs}
              details={details}
            />
          )}
          <TableBody>
            {stableSort(newRows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const cleanedRow = omit(row, ["id"]);

                return (
                  <TableRow key={row.id + index.toString()}>
                    {Object.values(cleanedRow).map((rowData, index) => {
                      return (
                        <TableCell
                          key={`${rowData} - ${index}`}
                          align="center"
                          width={`${
                            !tabs
                              ? 100 / (headers ? headers.length : 6)
                              : index === 0
                              ? 30
                              : 100 / (headers ? headers.length : 6 - 1)
                          }%`}
                          className={tabs ? (index === 0 ? className : "") : ""}
                        >
                          {!totalValue ? rowData : <b>{rowData}</b>}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && pagination && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={newRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
