import React from 'react';
import {Link} from 'react-router-dom';
import {AppRoutes} from '../../Routes';
import './Navbar.scss';
import {Box} from '@material-ui/core';

export enum NavbarNames {
    HOME = 'home',
    QUOTE_INSPECTOR = 'quote-inspector',
    SEGMENT = 'segment',
    TEMPLATE = 'template',
    CONTENT_TEMPLATE = 'content-template',
}

interface NavbarProps {
    title: string;
    domain:
        | NavbarNames.HOME
        | NavbarNames.QUOTE_INSPECTOR
        | NavbarNames.SEGMENT
        | NavbarNames.TEMPLATE
        | NavbarNames.CONTENT_TEMPLATE;
}
export const Navbar = (props: NavbarProps) => {
    const {title, domain} = props;

    return (
        <Box className='nav-bar'>
            <div className='nav-bar-title'>
                <h1>{title}</h1>
            </div>
            <div className='nav-bar-links'>
                <div>
                    <Link
                        className={`${domain === NavbarNames.HOME ? 'selected' : ''}`}
                        to={AppRoutes.HOME}>
                        Home
                    </Link>
                </div>
                <div>
                    <Link
                        className={`${domain === NavbarNames.QUOTE_INSPECTOR ? 'selected' : ''}`}
                        to={AppRoutes.QUOTE_INSPECTOR + '/channel/airbnb'}>
                        Quote Inspector
                    </Link>
                </div>
                <div>
                    <Link
                        className={`${domain === NavbarNames.SEGMENT ? 'selected' : ''}`}
                        to={AppRoutes.SEGMENT}>
                        Segments
                    </Link>
                </div>
                <div>
                    <Link
                        className={`${domain === NavbarNames.TEMPLATE ? 'selected' : ''}`}
                        to={AppRoutes.TEMPLATE}>
                        Template
                    </Link>
                </div>
                <div>
                    <Link
                        className={`${domain === NavbarNames.CONTENT_TEMPLATE ? 'selected' : ''}`}
                        to={AppRoutes.CONTENT_TEMPLATE}>
                        Content Template
                    </Link>
                </div>
            </div>
        </Box>
    );
};
