import React from 'react';
import {Tabs} from '@vacasa/react-components-lib';
import {BasicTable} from '../BasicTable/BasicTable';
import {TrackingChanges} from '../../utils';
import './BasicTab.scss';
import {Box} from '@material-ui/core';

interface BasicTabProps {
    data: TrackingChanges;
    selectedTab: number;
    handleTabChange: (index: number) => void;
    id: string;
}
export const BasicTab: React.FC<BasicTabProps> = (props) => {
    const {id, data, selectedTab, handleTabChange} = props;

    return (
        <div key={id} className='dynamic-tabs'>
            <Tabs
                key={id}
                selected={selectedTab}
                onChange={handleTabChange}
                tabs={[
                    {
                        id: id + '-tab-rent',
                        label: 'Rent',
                        component: (
                            <Box key={id + '-tab-rent'} className='box-container'>
                                <BasicTable
                                    id={id + '-tab-rent'}
                                    headers={data?.rent?.data?.headers ?? []}
                                    newRows={data?.rent?.data?.rows ?? []}
                                    pagination={false}
                                    className='fixed-tabs'
                                    tabs={true}
                                />
                            </Box>
                        ),
                    },
                    {
                        id: id + '-tab-fees',
                        label: 'Fees',
                        disabled: true,
                        component: <></>,
                    },
                    {
                        id: id + '-tab-taxes',
                        label: 'Taxes',
                        disabled: true,
                        component: <></>,
                    },
                ]}
            />
        </div>
    );
};
