import { Button, Icon } from "@vacasa/react-components-lib";
import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import "./ColumnTooltip.scss";

interface ColumnTooltipProps {
  id?: string;
  popperProps?: {};
  contentType: "text" | "element" | "markdown" | "json";
  content: JSX.Element | ((...params: any[]) => JSX.Element) | string;
  hasBottomDetailsLink?: boolean;
  link?: string;
  hasCloseButton?: boolean;
  title: string;
  type: string;
}

export const ColumnTooltip: React.FC<ColumnTooltipProps> = (props) => {
  const {
    contentType,
    content,
    hasCloseButton,
    hasBottomDetailsLink,
    popperProps,
    id,
    title,
    link,
    type
  } = props;
  const [isShown, setIsShown] = useState(false);
  let bodyContent = null;

  if (contentType === "element") {
    bodyContent = content;
  }

  const close = () => {
    setIsShown(false);
  };

  const tooltipContent = (
    <div style={{ width: "fit-content", margin: "15px" }}>
      <div className="tooltip-top">
        <span style={{ fontWeight: "bold" }}>{title}</span>
        {hasCloseButton && (
          <span className="close-button" onClick={close}>
            <Icon.XCircle height={18} />
          </span>
        )}
      </div>
      <div className="tooltip-body">{bodyContent}</div>
      <div className="tooltip-bottom">
        <div className="tooltip-bottom-left">
          {hasBottomDetailsLink && (
            <a href={hasBottomDetailsLink ? link : "#"} rel="noreferrer" target="_blank">
              Link
            </a>
          )}
        </div>
        <div>
          <Button variant="secondary" children={"Understood"} onClick={close} />
        </div>
      </div>
    </div>
  );

  return (
    <Tooltip
      disableFocusListener
      disableHoverListener
      open={isShown}
      placement="bottom"
      title={tooltipContent}
      style={{
        zIndex: 3,
        pointerEvents: "all",
      }}
      PopperProps={{
        placement: "bottom",
        modifiers: { flip: { enabled: false } },
        ...popperProps,
      }}
      id={`${id}-column-tooltip`}
    >
      <span
        onClick={() => setIsShown(!isShown)}
        className={`tooltip-icon ${type === "chips" ? "chips" : ""}`}
      >
        <Icon.AlertCircle height={12} />
      </span>
    </Tooltip>
  );
};
