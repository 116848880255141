import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {Configuration} from '../Configuration';
import {apiQuotes} from './api';

const rootReducer = combineReducers({
    [apiQuotes.reducerPath]: apiQuotes.reducer,
});
export const store = configureStore({
    devTools: !Configuration.isProduction,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiQuotes.middleware]),
});
