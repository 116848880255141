import React from 'react';
import {Box, Paper} from '@material-ui/core';
import './Home.scss';
import {Navbar, NavbarNames} from '../../components/';

export const Home = () => {
    return (
        <Box>
            <Paper elevation={3} className='home'>
                <div className='tabs-container'>
                    <Navbar title={''} domain={NavbarNames.HOME}></Navbar>
                </div>
            </Paper>
        </Box>
    );
};
