import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Channel,
  dataTransformation,
  DataTransformed,
  Query,
} from "../../utils";
import { useGetQuoteMutation } from "../../store";
import _ from "lodash";
import theme from "@vacasa/react-components-lib/lib/themes/default";
import {
  CustomizedAccordions,
  Loading,
  QuoteInspectorFilter,
} from "../../components";
import { Button, Icon, Tooltip } from "@vacasa/react-components-lib";
import { Box } from "@material-ui/core";
import AlertError from "../../components/AlertError/AlertError";
import "./QuoteInspector.scss";

export const QuoteInspector: React.FC = () => {
  const {
    channel,
    unitId,
    checkin,
    nights,
    guests,
    dogs,
    legacy_discount_id,
    quote_scenario_id,
  } =
    useParams<{
      channel: string;
      unitId: string;
      checkin: string;
      nights: string;
      guests: string;
      dogs: string;
      legacy_discount_id: string;
      quote_scenario_id: string;
    }>();
  const [params, setParams] = useState<Query>({
    channel,
    unitId: unitId ?? "",
    checkin,
    nights: nights ? parseInt(nights) : 2,
    guests: guests ? parseInt(guests) : 2,
    dogs: dogs ? parseInt(dogs) : 0,
    reservation_scenario_id: quote_scenario_id,
    legacy_discount_id,
    trip_protection: undefined,
  });
  const [scenarios, setScenarios] = useState<DataTransformed>({
    data: null,
    notBookableReason: null,
  });
  const [getQuotes, { data, isLoading, error, isError }] =
    useGetQuoteMutation();

  const [canOpenAlertError, setCanOpenAlertError] = useState<boolean>(true);
  const [disableViewMore, setDisableViewMore] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [isUnitIdCopy, setIsUnitIdCopy] = useState<boolean>(false);
  const [isReservationIdCopy, setIsReservationIdCopy] =
    useState<boolean>(false);

  const searchParameters = (aux) => {
    setScenarios(null);
    setParams({ ...aux, channel });
  };

  const searchViewMore = (aux) => {
    setViewMore(!viewMore);
    setParams({ ...aux, channel });
  };

  useEffect(() => {
    if (params.unitId) {
      getQuotes(params);
    }
  }, [params, getQuotes]);

  useEffect(() => {
    if (!isLoading && data) {
      const auxData = dataTransformation(data, channel);
      const mergeData: DataTransformed = {
        data: auxData.data,
        notBookableReason: auxData.notBookableReason,
      };
      if (viewMore) {
        mergeData.data = _.concat(scenarios.data, auxData.data);
      }
      if (auxData.notBookableReason === "No Data") {
        setDisableViewMore(true);
        setViewMore(false);
        return;
      }
      setScenarios(mergeData);
      setViewMore(false);
      setDisableViewMore(false);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading) {
      setCanOpenAlertError(true);
    }
  });

  const UnitId = () => {
    return (
      <div>
        {scenarios?.data[0]?.unit?.ids?.admin && (
          <div style={{ paddingBottom: "10px" }}>
            <span>
              <em>
                <b> Unit ID: </b>
              </em>
              {scenarios?.data[0].unit.ids.admin}
            </span>
            <Tooltip message={!isUnitIdCopy ? "Copy" : "Copied"}>
              <span
                onClick={() => {
                  navigator.clipboard.writeText(
                    scenarios?.data[0].unit.ids.admin
                  );
                  setIsUnitIdCopy(true);
                  setTimeout(() => {
                    setIsUnitIdCopy(false);
                  }, 5000);
                }}
                className={``}
              >
                <Icon.Copy style={{ paddingLeft: "5px" }} height={12} />
              </span>
            </Tooltip>
          </div>
        )}

        {scenarios?.data[0]?.reservation_scenario_id && (
          <div style={{ paddingBottom: "10px" }}>
            <span>
              <em>
                <b> Reservation ID: </b>
              </em>
              xxxxx
            </span>
            <Tooltip message={!isReservationIdCopy ? "Copy" : "Copied"}>
              <span
                onClick={() => {
                  navigator.clipboard.writeText(
                    scenarios?.data[0]?.reservation_scenario_id
                  );
                  setIsReservationIdCopy(true);
                  setTimeout(() => {
                    setIsReservationIdCopy(false);
                  }, 5000);
                }}
                className={``}
              >
                <Icon.Copy style={{ paddingLeft: "5px" }} height={12} />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  return (
    <Box>
      <div className={"initial-container"}>
        <div className="title">
          <span> Base Quote Inspect </span>
        </div>
        <div className="body">
          <QuoteInspectorFilter
            isLoading={isLoading}
            params={params}
            searchParameters={searchParameters}
            channel={channel === Channel.VACASA}
          />
          {scenarios?.data ? (
            <div className="accordion">
              <UnitId />
              <CustomizedAccordions
                notBookableReason={scenarios.notBookableReason}
                details={scenarios.data}
              />
            </div>
          ) : isLoading ? (
            <div className="modal-loading">
              <Loading />
            </div>
          ) : isError ? (
            <AlertError theme={theme} error={error} />
          ) : (
            <div className="whitespace-fill" />
          )}
          {scenarios?.data &&
            !scenarios?.notBookableReason &&
            !quote_scenario_id && (
              <div className="view-more">
                {!viewMore ? (
                  <div className="button-view-more">
                    <Button
                      disabled={disableViewMore}
                      type="button"
                      variant="secondary"
                      onClick={() =>
                        searchViewMore({
                          ...params,
                          offset: scenarios.data.length,
                        })
                      }
                    >
                      View More
                    </Button>
                  </div>
                ) : (
                  <div className="modal-loading">
                    <Loading />
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </Box>
  );
};
