import * as Universal from "@vacasa/universal-channels-types";

export interface DataTransformed {
  data: QuoteInspectorData[];
  notBookableReason?: string;
}

export type QuoteInspectorData = {
  id: number;
  channel: string;
  initialInformation?: InitialInformationForAccordion;
  notBookableReason: string;
  labels?: BaseHeader[];
  itineraryTable?: TableDefinitionBase;
  tabs?: TrackingChanges;
  fees?: ExtendedFinancialItem[];
  taxes?: ExtendedFinancialItem[];
  totals?: { [key: string]: any }[];
  unit?: any;
  reservation_scenario_id?: string;
};

export type ExtendedFinancialItem = Universal.Financial.Item & {
  evaluatedValue?: number;
  conditions?: { name: string; values: string[] }[];
};

export interface TrackingChanges {
  rent: TableDefinitionBase;
  taxes: TableDefinitionBase;
  fees: TableDefinitionBase;
}

export interface InitialInformationForAccordion {
  values: Rent;
  description: string;
  date: string;
}

export interface Rent {
  rent: number;
  taxes: number;
  fees: number;
  currency: string;
}

export interface TableDefinitionBase {
  id: string;
  data: Data;
  segments?: string[];
}

export interface Data {
  headers: any[];
  rows: { [key: string]: any }[];
}

export interface BaseHeader {
  id: string;
  label: string;
  priority?: string;
  details?: any;
  message?: Message;
}

export interface Message {
  explication?: string;
  title?: string;
  link?: string;
}

export interface Query {
  channel?: string;
  unitId?: string;
  checkin?: string | Date;
  nights?: number;
  guests?: number;
  dogs?: number;
  trip_protection?: boolean;
  offset?: number;
  reservation_scenario_id?: string;
  legacy_discount_id?: string;
}

export enum ItineraryTableHeaders {
  DATE = "Date",
  AVAILABILITY_RATE = "Availability Rate",
  MIN_RATE = "Min Rate",
  NIGHT_RATE = "Night Rate",
  DYNAMIC_DISCOUNTS = "Dynamic Discounts",
  POB = "POB",
  DISC = "DISC",
}

export enum TrackingChangeTableHeaders {
  ACTION = "action",
  RATES = "rates",
  VALUE = "value",
  TEMPLATE = "template",
  DESCRIPTION = "description",
}

export enum Channel {
  AIRBNB = "airbnb",
  BOOKING = "booking",
  VACASA = "vacasa",
}
