import React, { useState } from "react";
import "./QuoteInspectorFilter.scss";
import { Button, Input, Tooltip } from "@vacasa/react-components-lib";
import { Query } from "../../utils";
import { NumberInput } from "../NumberInput/NumberInput";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import _ from "lodash";
import moment from "moment";

interface SearchProps {
  searchParameters: (parameters) => void;
  params: Query;
  channel?: boolean;
  isLoading: boolean;
}

export const QuoteInspectorFilter: React.FC<SearchProps> = (props) => {
  const { searchParameters, params, channel, isLoading } = props;
  const [date, setDate] = useState<Date>(
    params?.checkin ? moment(params.checkin, "yyyy-MM-dd").toDate() : new Date()
  );
  const [query, setQuery] = useState<Query>(params);
  const [optionalParameters, setOptionalParameters] = useState<boolean>(false);

  const handleOptionalParameters = () => {
    if (!optionalParameters) {
      setQuery({
        ...query,
        legacy_discount_id: undefined,
        trip_protection: undefined,
      });
    }
    setOptionalParameters(!optionalParameters);
  };

  const handleChange = (value, key) => {
    setQuery((prev) => ({ ...prev, [key]: value }));
  };

  const handleSearch = () => {
    searchParameters({ ...query, checkin: format(date, "yyyy-MM-dd") });
  };

  const validateForm = () => {
    return !!(query.unitId && date && query.nights) && !isLoading;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="search" onKeyPress={handleKeyPress}>
      <div className="main-row">
        <div className={"second-row"}>
          <div className={"grid-matrix"}>
            <div className="search-items channel">
              <p>Unit UUID/Channel Unit Id</p>
              <Input
                customClass={
                  _.isEmpty(query?.unitId) ? "input id-invalid" : "input"
                }
                type="text"
                onChange={(e) => handleChange(e.target.value.trim(), "unitId")}
                value={query?.unitId}
              />
            </div>
            <div className="search-items checkin">
              <p>Checkin</p>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid className="date">
                  <KeyboardDatePicker
                    margin="normal"
                    id="checkin"
                    format="yyyy-MM-dd"
                    value={date}
                    onChange={(e) => setDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    helperText=""
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
            <div className="search-items number_input">
              <p>Nights</p>
              <NumberInput
                className={_.isNil(query?.nights) ? "invalid" : ""}
                value={query?.nights}
                max={540}
                min={0}
                onChange={(e) => handleChange(e, "nights")}
              />
            </div>
            <div className="search-items number_input">
              <p>Adults</p>
              <NumberInput
                className={_.isNil(query?.guests) ? "invalid" : ""}
                value={query?.guests}
                min={0}
                onChange={(e) => handleChange(e, "guests")}
              />
            </div>
            <div className="search-items number_input">
              <p>Dogs</p>
              <NumberInput
                className={_.isNil(query?.dogs) ? "invalid" : ""}
                value={query?.dogs}
                min={0}
                onChange={(e) => handleChange(e, "dogs")}
              />
            </div>
            <div className="search-items optional">
              <Tooltip
                message={
                  "If you need or want to add additional parameters selected this option "
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={optionalParameters}
                      onClick={handleOptionalParameters}
                    />
                  }
                  label="Additional Parameters"
                />
              </Tooltip>
            </div>
            {optionalParameters && (
              <div className="legacy">
                <p>Legacy Discount ID</p>
                <Input
                  customClass={"input"}
                  type="text"
                  onChange={(e) =>
                    handleChange(e.target.value.trim(), "legacy_discount_id")
                  }
                  value={query?.legacy_discount_id}
                />
              </div>
            )}
            {optionalParameters && channel && (
              <div className="optional">
                <FormControlLabel
                  control={<Checkbox value={query?.trip_protection} />}
                  label="Trip Protection"
                  labelPlacement={"start"}
                  onChange={(e) =>
                    handleChange(
                      (e.target as HTMLInputElement).checked,
                      "trip_protection"
                    )
                  }
                />
              </div>
            )}
          </div>
          <div className={"input-button"}>
            <Tooltip
              message={!validateForm() ? "You must place all the fields" : ""}
            >
              <div className="inspect">
                <Button
                  variant="secondary"
                  onClick={handleSearch}
                  disabled={!validateForm()}
                >
                  Inspect
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
