import {createBrowserHistory} from 'history';
import {datadogLogs} from '@datadog/browser-logs';
import {ThemeProvider} from '@vacasa/react-components-lib';
import {Box} from '@material-ui/core';
import {BrowserRouter, Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store';
import theme from '@vacasa/react-components-lib/lib/themes/default';
import {Routes} from './Routes';
import {StorageKeys} from './store/utils';

const {
    REACT_APP_DD_CLIENT_TOKEN: clientToken,
    REACT_APP_CUSTOM_NODE_ENV: environment,
    REACT_APP_CUSTOM_NODE_ENV_SHORT: env,
    REACT_APP_VERSION: app_version,
} = process.env;

export const history = createBrowserHistory();
const params = new URLSearchParams(window.location.search);
const access_token: string | null = params.get(StorageKeys.ACCESS_TOKEN);

if (access_token) {
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, access_token);
}

if (env !== 'local') {
    datadogLogs.init({
        clientToken,
        forwardErrorsToLogs: true,
        env,
    });

    datadogLogs.setLoggerGlobalContext({
        team: 'greedy-cashiers',
        service: 'quote-app',
        env,
        environment,
        app_version,
    });
}

function App() {
    const AppContainer = () => {
        return (
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Box className='initial-container'>
                        <Box className='second-container'>
                            <Box className='third-container'>
                                <Router history={history}>
                                    <Routes />
                                </Router>
                            </Box>
                        </Box>
                    </Box>
                </BrowserRouter>
            </ThemeProvider>
        );
    };

    return (
        //<ImplicitFlow {...Configuration.getIDPConfiguration()}>
        <Provider store={store}>
            <AppContainer />
        </Provider>
        //</ImplicitFlow>
    );
}

export default App;
