import {Query, urlBuilder} from '../utils';
import {apiQuotes} from './api';

export const quoteInspectorApi = apiQuotes.injectEndpoints({
    endpoints: (builder) => ({
        getQuote: builder.mutation<any[], Query>({
            query: (query) => {
                return urlBuilder("base-quote", query);
            },
            transformResponse: (response: {data}) => response.data,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Quote'],
        }),
    }),
});

export const {useGetQuoteMutation} = quoteInspectorApi;
